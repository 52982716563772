import { FunctionComponent } from 'react';
import classnames from 'classnames';
import './static.styl';
import { InPageMessaging, LayoutBox } from '@bamboohr/fabric';

const StaticTemplate: FunctionComponent<DynamicForm.StaticTemplateProps> = ({
	props,
	settings,
}) => {
	const createTemplate = (): React.ReactElement => {
		const { template } = settings;
		const { className } = props;
		if (template === 'dash') {
			const classes = classnames(
				'formDash',
				'DynamicForm--static__dash',
				className,
			);
			return (
				<span {...props} className={classes}>
					–
				</span>
			);
		}
		if (template === 'payrollSectionHeader') {
			return (
				<LayoutBox marginTop={4}>
					<InPageMessaging
						body={$.__(
							"Anything not completed here will be sent to your company's payroll admins to fill out.",
						)}
						header={$.__(
							"Not sure about some of the payroll info below? That's okay, just fill out as much as you know.",
						)}
						icon="circle-question-solid"
						type="info"
					/>
				</LayoutBox>
			);
		}
		return null;
	};

	return createTemplate();
};

export default StaticTemplate;
