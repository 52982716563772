import { FunctionComponent } from 'react';
import { Flex, SelectableBox, SelectableBoxGroup } from '@bamboohr/fabric';
import { Message, InternalLink } from '@bamboohr/utils/lib/message';
import './employee-access.styl';

const EmployeeAccessRegular: FunctionComponent<
	DynamicForm.EmployeeAccessModeProps
> = ({
	access,
	accessLevelText,
	context,
	handleSelectChange,
	hasEmailFields,
	props,
	renderAccessLevelOptions,
	renderEmailReminderText,
}) => {
	const { id, name } = props;
	const {
		controls: { FieldRow },
	} = context;
	return (
		<>
			{hasEmailFields && (
				<>
					<FieldRow context={context} props={{}} settings={{}}>
						<Flex data-bi-id="employees-add-employee-form-employee-access">
							<SelectableBoxGroup
								ariaLabel={$.__('Self-Service Access')}
								type="radio"
							>
								<Flex gap="24px" flexDirection="row" height="121px">
									<SelectableBox
										boxWidth="380px"
										description={accessLevelText}
										icon="user-check-solid"
										inputId={`${id}-enabled`}
										isChecked={access === 'enabled'}
										name={name}
										onChange={handleSelectChange}
										title={$.__('Allow Access to BambooHR')}
										type="radio"
										value="enabled"
									/>
									<SelectableBox
										boxWidth="380px"
										description={$.__(
											`They won't have access and will not be able to login to BambooHR.`,
										)}
										icon="ban-regular"
										inputId={`${id}-disabled`}
										isChecked={access !== 'enabled'}
										name={name}
										onChange={handleSelectChange}
										title={$.__('No Access')}
										type="radio"
										value="disabled"
									/>
								</Flex>
							</SelectableBoxGroup>
						</Flex>
					</FieldRow>
					{renderAccessLevelOptions()}
				</>
			)}
			<FieldRow context={context} props={{}} settings={{}}>
				<div className="fieldBox fab-FormField">
					{!hasEmailFields && (
						<span className="fab-FormNote DynamicForm--employeeAccess__noMargin">
							<Message
								link={InternalLink('new_fields.php')}
								text={$._(
									`To turn this on the employee needs an email and we don't detect an email on the page. Click [here] to add.`,
								)}
							/>
						</span>
					)}
					{renderEmailReminderText()}
				</div>
			</FieldRow>
		</>
	);
};

export default EmployeeAccessRegular;
