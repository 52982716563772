/**
 * NOTE: This currently does not support range date picking - That wasn't in QF and wasn't handled here
 * if it is needed in future, create it as a separate component so it can handle refs/validation differently!
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { DatePicker } from '@bamboohr/fabric';
import { useNonInitialEffect } from 'useNonInitialEffect';
import {
	getClassBasedValidation,
	hasErrors,
	isRequired,
	handleControlledInput,
	fixValidationRegisterName,
} from 'dynamic-form';
import { differenceInYears, isValid, formatISO, parse, format } from '@bamboohr/utils/lib/datetime';
import { setDateFnsLocale } from 'base/global/i18n/set-date-fns-locale';

const DateField: FunctionComponent<DynamicForm.DateElementProps> = (dateFieldProps) => {
	const {
		props,
		settings,
		context,
	} = dateFieldProps;
	const {
		controls: { TextField, PendingRequest },
		validation: { formState: { errors }, register, setValue },
	} = context;
	const { value, className, ...propsNoValue } = props;
	const { name, disabled } = propsNoValue;
	const isoDateRegex = /\d\d\d\d-\d\d-\d\d/g;
	const stringValue = value ? value.toString() : '';
	const defaultDate = value && !value.toString().match(isoDateRegex) ? formatISO(parse(value.toString(), window.dateFnsDefaultFormat, new Date()), { representation: 'date' }) : stringValue;
	const [dateValue, setDateValue] = useState<string>(defaultDate);

	const isFieldRequired = isRequired(context, dateFieldProps);
	const validationName = fixValidationRegisterName(name);
	const hasError = hasErrors(errors, validationName);
	register(validationName, { ...getClassBasedValidation(context, dateFieldProps), required: isFieldRequired });

	useEffect(() => {
		setValue(validationName, defaultDate);
	}, []);

	useNonInitialEffect(() => {
		const newValue = dateValue ? dateValue : '';
		handleControlledInput('text', props.id, newValue.toString(), context);
		// manually validate after change since the calendar picker won't trigger a blur event
		setValue(validationName, newValue, {
			shouldDirty: true,
			shouldValidate: true,
		});
	}, [dateValue]);

	const setSettingsNote = (date: string) => {
		if (!isValid(new Date(date))) {
			settings.note = null;
			return;
		}
		const age = differenceInYears(new Date(), new Date(date));
		settings.note = {text: $.__('Age: %1', age), type: "base"};
	};

	const handleDateChange = (dateObject: { value: string }): void => {
		const {
			value: date,
		} = dateObject;
		setDateValue(date);
		if (settings.token === 'textEmployeeBirthDate') {
			setSettingsNote(date);
		}
	};

	// Need to add the time stamp so that date won't convert the passed in date to the local timezone
	const currentDate = new Date(`${dateValue}T00:00:00`);

	setDateFnsLocale(`en-US`);
	const hiddenDateValue = isValid(currentDate) ? format(currentDate, window.dateFnsDefaultFormat) : '';
	setDateFnsLocale(document.documentElement.lang || 'en-US');

	return (
			<TextField
				context={context}
			input={ (
				<div className="dateFieldWrapper">
					<PendingRequest context={context} props={props} settings={settings} />
					<DatePicker
						disabled={ !!disabled }
						id={props.id}
						onChange={ handleDateChange }
						required={ isFieldRequired }
						status={hasError ? 'error' : undefined}
						value={dateValue}
					/>
					<input name={name} type='hidden' value={hiddenDateValue} />
				</div>
			) }
				props={props}
				settings={settings}
			/>
	);
};

export default DateField;
